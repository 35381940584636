import React, { Component, createContext } from "react";
import { auth, generateUserDocument } from "../firebase/db";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  state = {
    user: null,
    station: null,
    indexInStation:null,
    indexOfStation:null
    // setStation() => {}
  };
  
  componentDidMount = async () => {
    auth.onAuthStateChanged(async userAuth => {
      const user = await generateUserDocument(userAuth);
      this.setState({ user });
    });
  };

  setStationWithIndexes = (station, indexInStation, indexOfStation) => {
    this.setState({
      station: station,
      indexInStation: indexInStation,
      indexOfStation: indexOfStation
    });
  }

  render() {
    const { children } = this.props
    const { user, station, indexInStation, indexOfStation } = this.state;
    const {setStationWithIndexes} = this;
    return (
      <UserContext.Provider value={{user, station, indexInStation, indexOfStation, setStationWithIndexes}}>
        {children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
