import React, { Component } from 'react'
import Header from '../components/layout/Header';
import PropTypes from 'prop-types'

import favIcon from './layout/images/FavIcon.svg';
import logo from './layout/images/BlastLogo.svg';

import playButton from './layout/images/player/PlayButton.svg';
import pauseButton from './layout/images/player/PauseButton.svg';
import jumpForward from './layout/images/player/JumpForward.svg';
import jumpBack from './layout/images/player/JumpBack.svg';
import shareIcon from './layout/images/player/ShareIcon.svg';
import leftChevron from './layout/images/player/ChevronLeft.svg';
import rightChevron from './layout/images/player/ChevronRight.svg';

import './layout/stylesheets/Player.css';
import Footer from './layout/Footer';

import ReactPlayer from 'react-player';
import { Line } from 'rc-progress';

import { db, consolidateSessions } from '../firebase/db';
import { UserContext } from "../providers/UserProvider";
import withRouter from "react-router-dom/withRouter";
  
import PlayerSegments from './PlayerSegments';

const min5 = 5.0 * 60.0
class Player extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.state = { 
            width: 0, 
            height: 0, 
            duration:0,
            progressPercent:0, 
            progressBufferPercent:0,
            progressSeconds:0,
            progressColor:'#ED3283',
            playing:true,
            stations:null,
            indexInStation: 0,
            session:null,
            segments: [],
            isSingleSession: true,  // user has only one session in this station
            segmentIndex: 0,
            // player:null
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }  

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        db.collection('stations') // move into db.js
        .where("ownContentOnly", "==", false)
        .where("subscribers", "array-contains",this.props.user ? this.props.user.uid : "dcZIJBcfbQWeqWANb0bgt1alujL2" )
        .orderBy("order")
        .get().then(
            (snap) => {
                const stations = [];
                snap.docs.forEach((doc) => {
                    var sessions = [];
                    for (var key in doc.data().sessions) { 
                        var s = doc.data().sessions[key];
                        s.id = key;
                        sessions.push(s);
                    }
                    sessions = consolidateSessions(sessions);
                    stations.push({
                        id: doc.id,
                        title: doc.data().title,
                        sessions: consolidateSessions(sessions),
                        imageURL: doc.data().imageURL
                    });
                });
                this.setState({stations:stations});
            }
        );
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    
    seekTo = (amount) => {
        this.player.seekTo(amount, 'seconds'); // type?: 'seconds' | 'fraction'
    }

    getCurrentTime() {
        return ReactPlayer.getCurrentTime();
    }

    getSecondsLoaded() {
        return ReactPlayer.getSecondsLoaded();
    }

    getDuration() {
        return ReactPlayer.getDuration();
    }

    ref = player => {
        this.player = player
    }
    
    isSingleSession(session) {
        return session ? (session.consolidatedSessions?false:true)  : false;
    }

    setupConsolidatedSegmentValues = (session) => {

        let segments = [];
        // console.log('segmentIndex: ' + this.state.segmentIndex);
        // console.log('session.consolidatedSessions.length: ' + session.consolidatedSessions.length);
        session.consolidatedSessions.forEach((session, index) => {
            if (index === this.state.segmentIndex) {
                segments.push(this.state.progressPercent/100.0);
            }
            else {
                if (index < this.state.segmentIndex) {
                    segments.push(1.0);
                }
                else {
                    segments.push(0.0);
                }
            }
            
        });        
        this.state.segments = segments;
    }

    setupSingleUserSegmentValues = (session) => {
        let segmentCount = 0;
        let segments = [];
        let duration = this.state.duration;
        var progress = this.state.progressSeconds;
        if (duration > 0.01) {
            segmentCount = Math.ceil(duration / min5)
            for (var i = 0; i < segmentCount; i++) {
                let progressInSegment = false;
                let progressBeforeSegment = false;
                let progressAfterSegment = false;
                let segmentBegin = i * min5;
                let segmentEnd = (i+1) * min5
                if (progress > segmentBegin) {
                    if (progress < segmentEnd) {
                        progressInSegment = true;
                    }
                    else {
                        progressAfterSegment = true;
                    }
                }
                else {
                    progressBeforeSegment = true;
                }

                if (progressInSegment) {
                    console.log('progressInSegment of single user')
                    this.state.segmentIndex = i;
                    let inSegment = (progress - i * min5) / min5; 
                    segments.push(inSegment);
                }
                else if (progressBeforeSegment) {
                    segments.push(0.0);
                }
                else if (progressAfterSegment) {
                    segments.push(1.0);
                }
                else {
                    console.log("Unknown segment position in audio player!");
                }
            }
            this.state.segments = segments;
        }
    }

    switchSegment = (change) => {
        if (this.state.isSingleSession) { 
            if  (this.state.progressSeconds + (change * min5) > this.state.duration) {
                this.state.segmentIndex = 0;
                this.switchBroadcaster(1);
            }
            else {
                this.seekTo((this.state.segmentIndex + change) * min5);
            }
        }
        else {
            console.log('right segment arrow');
            if (this.state.segmentIndex + change > this.state.session.consolidatedSessions.length-1) {
                this.state.segmentIndex = 0;
                this.switchBroadcaster(1);
            }
            else {
                this.setState({
                    segmentIndex: Math.max(
                        0, 
                        Math.min(
                            this.state.segmentIndex + change, 
                            this.state.session.consolidatedSessions.length-1
                            )
                        ) 
                });
            }

        }
    }

    switchBroadcaster = (change) => {
        console.log('switchBroadcaster: ' + change);
        let {station, setStationWithIndexes, indexInStation, indexOfStation} = this.context;
        // let indexInStation = this.context.indexInStation;
        if (station) { 
            console.log('found station with currentIndex: ' + indexInStation + ', newIndex: ' + indexInStation + change);
            let newIndex = Math.min(indexInStation + change, station.sessions.length-1);
            this.state.segmentIndex = 0;
            setStationWithIndexes(station, Math.max(0, newIndex) , indexOfStation);
            // this.setState({station:station});
            let link = "/" + station.sessions[newIndex].authorName.replace(/ /g, '');
            this.props.history.push(link);
        }
        else {
            let stations = this.state.stations;
            let session = this.state.session;
            if (stations) {
                if (session) {
                    let iInStation = -1;
                    let iOfStation = -1;
                    // find a station with matching session
                    console.log('stations.length : ' + stations.length);
                    let found = false;
                    for (var i = 0; i < stations.length; i++) {
                        let st = stations[i];
                        if (found) break;
                        for (var j = 0; j < st.sessions.length; j++) {
                            let s = st.sessions[j];
                            if (s.id === session.id) {
                                // match
                                found = true;
                                iInStation = Math.max(0, Math.min(st.sessions.length-1, j + change) )  ;
                                iOfStation = i;
                                station = st;
                                session = s;
                                break;
                            }
                            console.log('station: ' + st.title + ', s.id: ' + s.id + ', session.id' + session.id);
                        }
                    }
                    if (found) {
                        console.log('found match');
                        // set new station with indexes in provider and call setState to render
                        console.log('NEW indexInStation: ' + iInStation);
                        this.state.segmentIndex = 0;
                        setStationWithIndexes(station, iInStation, iOfStation);
                        let link = "/" + session.authorName.replace(/ /g, '');
                        this.props.history.push(link);
                    }
                }
                else {
                    console.log("no player session");
                }
              
            }
            else {
                console.log("no stations");
            }
        }
    }

    ended = () => {
        console.log('ENDED');
        if (this.state.isSingleSession) {
            console.log('ended single session, should play next in station');
            this.state.segmentIndex = 0;
            this.switchBroadcaster(1);
        }
        else {
            this.switchSegment(1);
        }
    }

    secondsToString = (secs) => {
     return new Date(secs * 1000).toISOString().substr(11, 8);
    }

    render() {       
        const { station, indexInStation, indexOfStation } = this.context;
        const { broadcastingUser } = this.props;
        let session = null;
        if (station) {
            session = station.sessions[indexInStation];
        }

        if (!session && !station) {
            if (this.state.stations) {
                var ii=0, jj=0;
                // find index of user that matches path
                this.state.stations.some((st, i) => {
                    return st.sessions.some((s, j) => {
                      if(this.props.pathUser.toUpperCase().replace(/ /g, '') === s.authorName.toUpperCase().replace(/ /g, '') ) {
                          ii = i;
                          jj = j;
                          return true;
                      } else {
                        return false;
                      }
                    })
                } );
                session = this.state.stations[ii].sessions[jj];
            }
        }


        if (session) {
            this.state.isSingleSession = this.isSingleSession(session);
            if (this.state.isSingleSession) {
                // user has only one session in this station
                this.setupSingleUserSegmentValues(session);
            }
            else {
                // user has multiple sessions consolidated
                this.setupConsolidatedSegmentValues(session);
            }
        }
        // console.log("isSingleSession " + isSingleSession);
        this.state.session = session;
        return (
            // player
            // segments
            // artwork
            // progress-bar
            // player-buttons
            <div>
                <div>
                    <ReactPlayer 
                        ref={this.ref}
                        width={0} 
                        height={0} 
                        url={this.state.isSingleSession 
                            ? (session ? session.audioURL : '') 
                            : (session.consolidatedSessions[this.state.segmentIndex].audioURL ? session.consolidatedSessions[this.state.segmentIndex].audioURL : session.audioURL)} 
                        autoPlay
                        playing={this.state.playing} 
                        onReady={() => { console.log("onReady"); }  }
                        onStart={() => { } }
                        onPlay={() => { 
                            console.log('onPlay');
                            this.setState({playing:true});

                        } }
                        onPause={() => { console.log('onPause')}}
                        onBuffer={() => {}}
                        onBufferEnd={() => {}}
                        onEnded={() => {
                            this.ended();
                        }}
                        onError={(params) => {}}
                        onDuration={(duration) => {
                            console.log('duration' + duration);
                            this.setState({
                                duration: duration,
                            })
                        }}
                        onSeek={(seconds) => {
                            console.log('seek secs: ' + seconds);
                        }}
                        // onEnded={() => {}}
                        onProgress={(state) => {
                            // console.log(state);
                            this.setState({
                                progressPercent: state.played*100,
                                progressBufferPercent: state.loaded*100,
                                progressSeconds: state.playedSeconds
                            });
                        }}
                    />
                </div>
                <Header/>
                <div className='centered'>
                    <button
                        type='button'
                        className='btn'
                        style={{borderWidth:0, backgroundColor: '#FFF', boxShadow: 'none'}}
                        onClick={() => {  this.switchSegment(-1); }}
                    >
                        <img src={leftChevron} alt="leftSegmentArrow" style={{ height: 13.33, width: 7.5 }}  />
                    </button>
                    <PlayerSegments values={this.state.segments} width={Math.min(this.state.width-64, this.state.height/1.5)} />
                    <button
                        type='button'
                        className='btn'
                        style={{borderWidth:0, backgroundColor: '#FFF', boxShadow: 'none'}}
                        onClick={() => { this.switchSegment(1);}}
                    >
                        <img src={rightChevron} alt="rightSegmentArrow" style={{ height: 13.33, width: 7.5 }}  />
                    </button>
                </div>
                <div className='centered' >
                    <button
                        type='button'
                        className='btn'
                        style={{borderWidth:0, backgroundColor: '#FFF', boxShadow: 'none'}}
                        onClick={() => { this.switchBroadcaster(-1) }}
                    >
                        <img src={leftChevron} alt="previousUserArrow" style={{ height: 26.67, width: 15 }}  />
                    </button>
                    <img className='playerArtwork' src={session ? session.imageURL : logo} alt="logo" style={{ height: Math.min(this.state.width-64, this.state.height/1.5) , width: Math.min(this.state.width-64, this.state.height/1.5) }}  />
                    <button
                        type='button'
                        className='btn'
                        style={{borderWidth:0, backgroundColor: '#FFF', boxShadow: 'none'}}
                        onClick={() => { this.switchBroadcaster(1) }}
                    >
                        <img src={rightChevron} alt="nextUserArrow" style={{ height: 26.67, width: 15 }}  />
                    </button>                    
                </div>
  
                <div 
                    className='centered'  
                    style={{ 
                        position: 'relative', 
                        marginTop:0, 
                        marginBottom:8,
                }}>

                    <Line
                        percent={[this.state.progressPercent, this.state.progressBufferPercent]}
                        strokeWidth="2"
                        strokeColor={[this.state.progressColor, '#CCC']}
                        style={{ height: 16, marginTop:10, width: Math.min(this.state.width-64, this.state.height/1.5) }}
                        // strokeColor={color}
                    />
                </div>
                <div style={{height:0, marginTop:0, marginRight:4}}  className="centered">
                    <div style={{ height:0, width: Math.min(this.state.width-64, this.state.height/1.5)}} className="progress-duration" >
                        <p>{this.secondsToString(this.state.progressSeconds)} / {this.secondsToString(this.state.duration)}</p>
                    </div>
                </div>       
                <div className='centered btn-toolbar'>
                    <button
                        type='button'
                        className='btn'
                        style={{borderWidth:0, backgroundColor: '#FFF', boxShadow: 'none'}}
                        onClick={() => { console.log('favIcon')}}
                    >
                        <img src={favIcon} alt="favIcon" style={{ height: 32, width: 32 }}  />
                    </button>
                    <button
                        type='button'
                        className='btn'
                        style={{borderWidth:0, backgroundColor: '#FFF', boxShadow: 'none'}}
                        onClick={() => { 
                            console.log('jumpBack');
                            this.seekTo(this.state.progressSeconds - 15);
                        }}
                    >
                        <img src={jumpBack} alt="jumpBack" style={{ height: 32, width: 32 }}  />
                    </button>
                    <button
                        type='button'
                        className='btn'
                        style={{borderWidth:0, backgroundColor: '#FFF', boxShadow: 'none'}}
                        onClick={() => { 
                            console.log('play/pause: ' + this.player);

                            
                            console.log('this.player.playing: ' + this.player.playing);
                            // this.player.playing = !this.state.playing;
                            this.setState({playing:!this.state.playing});
                        }}
                    >
                        <img src={this.state.playing ? pauseButton : playButton} alt="playButton" style={{ height: 88, width: 90 }}  />
                    </button>
                    <button
                        type='button'
                        className='btn'
                        style={{borderWidth:0, backgroundColor: '#FFF', boxShadow: 'none'}}
                        onClick={() => { 
                            console.log('jumpForward');
                            this.seekTo(this.state.progressSeconds + 15);
                        }}
                    >
                        <img src={jumpForward} alt="jumpForward" style={{ height: 32, width: 32 }}  />
                    </button>
                    <button
                        type='button'
                        className='btn'
                        style={{borderWidth:0, backgroundColor: '#FFF', boxShadow: 'none'}}
                        onClick={() => { console.log('shareIcon')}}
                    >
                        <img src={shareIcon} alt="shareIcon" style={{ height: 31, width: 23 }}  />
                    </button>
                </div>
                <Footer/>
            </div>
        )
    }
}

// PropTypes
Player.propTypes = {
  broadcastingUser: PropTypes.object.isRequired,
}

export default withRouter(Player)