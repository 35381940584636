import React, { Component } from 'react';
import './App.css';
import './firebase/db';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import UserProvider from "./providers/UserProvider";
import Application from "./components/Application";
import Login from "./components/Login";
import Profile from "./components/Profile";
import { AuthProvider } from "./providers/AuthProvider";
import PrivateRoute from "./routes/PrivateRoute";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";


export default class App extends Component {
  render() {
    return (
      <AuthProvider>
        <Router>
          <div className='App body' style={{backgroundColor: '##FFFFF0'}}>
            <Switch>
              
              {/* TODO: someone needs to ensure that you can't create user accounts with "sign-in" and "blast-profile" as names */}
              <PrivateRoute exact path="/blast-profile" component={Profile} />
              <Route exact path="/sign-in">
                <Header />
                <Login path="/" />
                <Footer />
              </Route>
              
              <Route path='/:id' children={ 
                <UserProvider> 
                  <Application />
                  {/* <StationProvider>
                    {station => (
                    <Application station={station} /> 
                    )}
                  </StationProvider> */}
                  
                </UserProvider>}>                
              </Route>

              <Route path="/">
                <UserProvider>
                <Application />
                  {/* <StationProvider>
                  {station => (
                    <Application station={station}/> 
                    )}
                  </StationProvider> */}
                </UserProvider>
              </Route>
            </Switch>
          </div>
        </Router>
      </AuthProvider>
    );
  }
}
