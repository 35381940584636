import React, { Component } from 'react'
import Stations from './Stations';

import Header from './layout/Header';
import Footer from './layout/Footer';

import {db, consolidateSessions} from '../firebase/db';

class Home extends Component {
    state = {
        stations: []
    }

    constructor() {
        super();
        this.state = {
          stations : []
        }
    }
    
    //TODO: implement the onValue changed methods to update on firestore changes
    componentDidMount() {
        db.collection('stations')
        .where("ownContentOnly", "==", false)
        .where("subscribers", "array-contains", this.props.user ? this.props.user.uid : "dcZIJBcfbQWeqWANb0bgt1alujL2" )
        .orderBy("order")
        .get().then(
            (snap) => {
                const stations = [];
                snap.docs.forEach((doc) => {
                    var sessions = [];
                    for (var key in doc.data().sessions) { 
                        var s = doc.data().sessions[key];
                        s.id = key;
                        sessions.push(s);
                    }
                    sessions = consolidateSessions(sessions);
                    stations.push({
                        id: doc.id,
                        title: doc.data().title,
                        sessions: consolidateSessions(sessions),
                        imageURL: doc.data().imageURL
                    });
                });
                this.setState({stations:stations});
            }
        );
    }

    render() {
      return ( 
        <div>
            <Header />
            <Stations stations={this.state.stations} />
            <Footer/>
        </div>
      )
    }
}

export default Home