import Home from './Home'
import { UserContext} from "../providers/UserProvider";
import React, {Component } from "react";
import {
    BrowserRouter as Router,
    withRouter
  } from "react-router-dom";
import SignIn from "./Login";
import Player from './Player'
import { getBroadcastingUsers } from '../firebase/db';
  
class Application extends Component {
  static contextType = UserContext;
  
  constructor() {
    super();
    this.state= {broadcastingUsers: [], station:null};
  }

  componentDidMount() {
    getBroadcastingUsers().then((users) => {
      this.setState({
        broadcastingUsers: users
      });
    });
  }
  
  render() {
    const {station, indexInStation, indexOfStation } = this.context;
    // console.log('provided station: ' + station);

    const { id } = this.props.match.params;
    let pathMatchesBroadcastingUser = false;
    var broadcastingUser; 
    if (id && this.state.broadcastingUsers) {
      pathMatchesBroadcastingUser = this.state.broadcastingUsers.some((user) => {
        if (id.toUpperCase() === user.data().name.toUpperCase().replace(/ /g, '')) {
          // console.log('id: ' + id + ' user.name: ' + user.data().name);
          broadcastingUser = user.data();
          console.log('path match');
          return true; 
        } else {
          return false;
        }
      });
    }
    if (true) { // no need for user auth
      if (this.state.id && !pathMatchesBroadcastingUser) {
        this.props.history.push('/');
      }
      // console.log('pathMatchesBroadcastingUser: ' + pathMatchesBroadcastingUser);
      return (pathMatchesBroadcastingUser 
        ? <Player  station={station} indexInStation={indexInStation} indexOfStation={indexOfStation} broadcastingUser={broadcastingUser} pathUser={id}/> 
        : <Home /* user={user} *//>);
    }
    else {
      return (<Router>
        <SignIn path="/" />
      </Router>);
    }
  }
}

export default withRouter(Application)
