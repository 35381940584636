import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withRouter from "react-router-dom/withRouter";

import { UserContext} from "../providers/UserProvider";
import './layout/stylesheets/SessionItem.css';
class SessionItem extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = { 
      width: 0, 
      height: 0, 
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  play = (event) => {
    event.preventDefault();
    const {station, user, setStationWithIndexes} = this.context;
    setStationWithIndexes(this.props.station,this.props.index,this.props.indexOfStation);   
    console.log(this.props.session);
    let link = "/" + this.props.session.authorName.replace(/ /g, '');
    this.props.history.push(link);
  };

  render() {
    const {imageURL} = this.props.session;
    return (
      <div >
          <button 
              style={{width:this.state.width-50, height:80}} 
              className='item'
              onClick = {(event) => {this.play(event)}}
          >   
          <div className='sessiondiv'>
              <div className='sessionImage'>
                  <img className='sessionImage' src={imageURL} alt="sessionArtwork" style={{ height: 60, width: 60 }}  />
              </div>
              <p className='sessionHeading'>
                  { this.props.session.authorName }
              </p>
          </div>
          </button>
      </div>
    )
  }
}

// PropTypes
SessionItem.propTypes = {
    session: PropTypes.object.isRequired
}

export default withRouter(SessionItem)
