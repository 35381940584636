import React from "react";
import { auth } from "../firebase/db";

const Profile = () => {
  return (
    <>
      <h1>Home</h1>
      <button onClick={() => auth.signOut()}>Sign out</button>
    </>
  );
};

export default Profile;