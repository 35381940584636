import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {rgba} from 'polished'
import SessionItem from './SessionItem';
import './layout/stylesheets/StationItem.css';

const itemStyle = {
    minHeight:100,
    borderRadius: 8,
    backgroundColor: rgba(237, 50, 131, 0.08),
    // boxShadow: "1px 3px 1px #9E9E9E",
    marginBottom:20
}

export class StationItem extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            width: 0, 
            height: 0, 
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }  
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    render() {
        const {station} = this.props;
        const {title, imageURL} = station;
        let {width, height} = this.state;
        const sessionsList = station.sessions.map((session, index) => 
            <SessionItem indexOfStation={this.props.index} index={index} key={session.id} session={session} station={station}/>
        );

        return (
        <div className='itemStyle' style={itemStyle}>
            <div className='stationImage'>
                <img className='stationImage' src={imageURL} alt="stationArtwork" style={{ height: (176.0/359.0)*width, width: "100%" }}  />
            </div>
            <div className='stationHeading'>
                <p style={{textColor: '#0f0'}} >{title}</p>
            </div>

          <div className='stationSessionList'>
            {sessionsList}
          </div>

        </div>
        );
    }
}

// PropTypes
StationItem.propTypes = {
    station: PropTypes.object.isRequired
}

export default StationItem


