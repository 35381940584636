import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Line } from 'rc-progress';

class Segment extends Component {

    render() {
        let containerStyle = {
            marginTop:8,
            marginLeft:2,
            marginRight:2,
            marginBottom:20,
            justifyContent: 'center',
            alignItems: 'center',
        };
        return (
          <div style={containerStyle}>
              <Line
                  percent={this.props.value * 100}
                  strokeWidth="6"
                  strokeColor={'#ED3283'}
                  style={{height: 6, width: this.props.width}}
              />
          </div>
        )
    }
}

// PropTypes Segment
Segment.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    // totalCount: PropTypes.number.isRequired,
    // totalWidth: PropTypes.number.isRequired,
}

class PlayerSegments extends Component {
  render() {
      return this.props.values.map((value, index) => (
        <Segment 
            key={index}
            index={index} 
            value={value} 
            width={(this.props.width / this.props.values.length) -4}
        />
      ));
  }
}

// PropTypes PlayerSegments
PlayerSegments.propTypes = {
  values: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
}

export default PlayerSegments