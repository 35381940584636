import firebase from 'firebase';
import firebaseApp from './firebaseConfig'

export const auth = firebaseApp.auth();
export const db = firebaseApp.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth().signInWithPopup(provider);
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = db.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    // bootstrap user
      var bootstrap = firebaseApp.functions().httpsCallable('bootStrapNewUser');
      bootstrap({userId: user.uid, name: user.name}).then(function(result) {
        console.log('user bootstrap returned: ' + result.data);
        return getUserDocument(user.uid);
      }).catch(function(error){
        console.error("Error with user bootstrap: ", error);
      });
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    const userDocument = await db.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data()
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

export const getBroadcastingUsers = async () => {
  try {
    var users = [];
    const usersSnap = await db.collection('users').get();
    const stationsSnap = await db.collection('stations').where("public", "==", true).get();
    usersSnap.forEach((user) => {
      let broadcasting = false;
      stationsSnap.forEach((station) => { //TODO: optimize with for-break (or better, mark user with isBroadcasting with cloud function when adding or removing sessions from public)
        let sessions = station.data().sessions;
        for (let s in sessions) {
          let name = sessions[s].authorName.replace(/ /g, ''); //remove spaces

          if(user.data().name != null)
          {
            if (name === user.data().name.replace(/ /g, '')) {
              broadcasting = true;
            }
          }
        }
      });
      if (broadcasting) {
        users.push(user);
      }
    });
    return users;
  } catch (error) {
    console.log('Error fetching broadcasting users', error);
  }
};

export const consolidateSessions = (sessions) => {
  //TODO: don't call consolidateSessions on every render but on db value changed.
 sessions.sort(function(a, b) {
     return b.timestamp - a.timestamp; 
 });
 var userIds = [];
 for (var i = 0; i < sessions.length; i++) {
     var session = sessions[i];
     if ((userIds.indexOf(session.authorId) > -1)) { //contains
         for (var j = i-1; j > -1; j--) {
             var prevSession = sessions[j];
             if (prevSession.authorId === session.authorId) {
                 if ('consolidatedSessions' in prevSession === false || prevSession.consolidatedSessions.length === 0) {
                     // add it self as first session
                     prevSession.consolidatedSessions = [];
                     prevSession.consolidatedSessions.push(prevSession);
                 }
                 prevSession.consolidatedSessions.push(session);
                 sessions.splice(i,1);
                 i--;
                 break;
             }
         }
     }
     else {
         userIds.push(session.authorId);
     }
 }
 return sessions;
}
