import React, { Component } from 'react'
import './stylesheets/Footer.css'

const Content = () => (
  <p className="copyright">
    &#169; Blast Media
    <a 
        className="policies" 
        target="_blank" 
        rel="noopener noreferrer"
        href="https://www.termsfeed.com/live/0a4524ba-33d4-4a5b-b07d-413c25f5c681">Privacy policy
    </a>
  </p>
)

class Footer extends Component {
    render() {
        return (
          <div className="fixed-bottom centered">
            <Content></Content>
          </div>
        )
    }
}

export default Footer