import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import './stylesheets/Header.css'
import logo from './images/BlastLogo.svg';

const Content = withRouter(({ history }) => (
    <button
      type='button'
      className='btn'
      style={{borderWidth:0, backgroundColor: '#FFF', boxShadow: 'none'}}
      onClick={() => { history.push('/')}}
    >
      <img src={logo} alt="logo" style={{ height: 80, width: 80 }}  />
    </button>
  )
)

class Header extends Component {
  render() {
    return (
      <div className='centered'>
        <Content></Content>
      </div>
    )
  }
}

export default Header